<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/room" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="订单详情" width="1000" height="600" show-zoom>
    <template v-slot>
      <OrderDetails :selectRow="selectRow" />
    </template>
  </vxe-modal>
  <vxe-modal ref="audit" title="审核" width="600" resize remember>
    <template #default>
      <vxe-form :data="formData" ref="refundForm" :rules="rules" title-width="100" v-loading="loading" @submit="aprovalGrantedEvent('yes')" @reset="aprovalGrantedEvent('no')">
        <vxe-form-item title="备注" field="remark" span="24">
          <template #default>
            <vxe-textarea v-model="formData.remark" maxlength="300"></vxe-textarea>
          </template>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">通过</vxe-button>
            <vxe-button type="reset">驳回</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </template>
  </vxe-modal>
  <vxe-modal ref="xModalLog" title="订单日志" width="800" height="500">
    <template v-slot>
      <div class="log">
        <el-steps direction="vertical" :active="logList.length">
          <el-step :title="`${item.orderTrajectory}【${item.creatorName}】`" :description="`${item.updateTime}${item.remark ? item.remark : ''}`" v-for="(item,index) in logList" :key="index"></el-step>
        </el-steps>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {

  data() {
    return {
      loading: false,
      logList: [],
      formData: {},
      rules: {
        remark: [
          { required: true, message: "请输入退费原因" },
        ]
      },
      selectRow: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "roomName",
            title: "房屋编号",
            minWidth: 120,
            editRender: {
              name: "$select",
            }
          },
          {
            field: "orderId",
            title: "退费单号",
            minWidth: 260,
            editRender: {
              name: "$VxeRefundsInfo",
              props: {
                service: '/order/orderRefund/detail',
                optionCode: 'refundStatus',
                type: 'text',
                status: "primary",
              },
            }
          },
          {
            field: "totalAmount",
            title: "退费金额",
            minWidth: 100,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: "remark",
            title: "退费原因",
            minWidth: 220,
          },
          {
            field: "serialNumber",
            title: "银行流水号",
            minWidth: 220,
          },
          {
            field: "payType",
            title: "支付类型",
            minWidth: 150,
            editRender: {
              name: "$select",
              optionTypeCode: "payType",
              props: {
                type: 'text',
                status: "primary",
              },
            }
          },
          {
            field: "creatorName",
            title: "操作人",
            minWidth: 120,
          },
          {
            field: "status",
            title: "订单状态",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "refundStatus",
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "audit",
                  status: "primary",
                  label: "审核",
                  params: {
                    property: "status",
                    value: ["10"]
                  },
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.formData.remark = "";
                      this.$refs.audit.open();
                    }
                  }
                },
                {
                  name: "viewLog",
                  status: "primary",
                  label: "查看日志",
                  events: {
                    click: (row) => {
                      this.checkLog(row.id)
                    }
                  }
                },
              ],
            },
          },
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [],
        //表单校验规则
        rules: {
          remark: [
            { required: true, message: "请输入退费原因" },
          ]
        },
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "roomName",
            title: "房间号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房间号",
              },
            },
          },
          {
            field: "orderId",
            title: "退费单号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入退费单号",
              },
            },
          },
          {
            field: "serialNumber",
            title: "银行流水号",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入银行流水号",
              },
            }
          },
          {
            field: "startTime",
            title: "开始时间",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择费用开始时间"
              }
            }
          },
          {
            field: "endTime",
            title: "结束时间",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd 23:59:59",
                placeholder: "请选择费用结束时间"
              }
            }
          },
          {
            field: "payType",
            title: "支付方式",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "payType",
              props: {
                type: 'text',
                status: "primary",
                placeholder: "请选择支付方式",
              },
            }
          },
          {
            field: "status",
            title: "订单状态",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "orderStatus",
              props: {
                placeholder: "请选择订单状态",
              },
            }
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          roomName: "",
          orderId: "",
          payType: "",
          startTime: "",
          endTime: "",
          status: "",
        },
      },
    };
  },
  methods: {
    ...mapActions(['submitEventService', 'postEventService', 'findByEventService']),
    handleNodeClick(treeData, node) {
      let { data } = this.searchOptions;
      data["parentId"] = node.id;
      data["parentType"] = node.type;
      this.$refs.xGrid.getLists();
    },

    // 点击通过，驳回
    aprovalGrantedEvent(servicetyep) {
      this.$refs["refundForm"].validate((valid) => {
        if (!valid) {
          if (this.loading) return;
          this.loading = true
          this.postEventService({
            service: '/order/orderRefund/approve',
            params: {
              id: this.selectRow.id,
              remark: this.selectRow.remark,
              status: servicetyep
            },
          }).then(({ code, message }) => {
            if (code == 200) {
              this.$message.success(servicetyep == 'yes' ? '审核通过' : '已驳回')
            }
            this.$refs["refundForm"].reset();
            this.$refs.xGrid.getLists();
            this.$refs.audit.close();
            this.loading = false
          })
        }
      });
    },

    // 查看日志
    checkLog(refundId) {
      this.findByEventService({
        service: '/order/orderLog',
        params: {
          refundId
        },
      }).then(({ code, data, message }) => {
        if (code == 200) {
          this.logList = data
          this.$refs.xModalLog.open();
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.log {
  box-sizing: border-box;
  height: 100%;
  padding: 15px;
}
</style>
